import React from 'react';
import clsx from 'clsx';
import * as styles from './ThankYou.module.css';

const ThankYou = ({ children, buttonLink, buttonText }) => {
  return (
    <div className='flex items-center flex-col ml-[-2.4rem]'>
      {children}
      <a href={buttonLink} className={clsx(styles.back,'mt-[2.5rem]')}>{buttonText}</a>
    </div>
  );
};

export default ThankYou;
