import React from 'react';
import * as styles from '../../styles/pages/shortHeightContent.module.css';
import GlobalWrapper from '../../components/GlobalWrapper/GlobalWrapper';
import ThankYou from '../../components/Form/ThankYou/ThankYou';
import Layout from '../../components/Layout';
import Title from '../../components/Title/Title';
import Footnotes from '../../components/Footnotes/Footnotes';

import { GtagHead } from '../../components/GTAG';
export function Head() {
    return (
      <>
        <GtagHead/>
        <title>Get updates | LOQTORZI® (toripalimab-tpzi) thank you</title>
        <meta name='description' content='Thank you for signing up for updates and information about LOQTORZI®'/>
      </>
    )
}

export default function GetUpdatesConfirmation() {
  return (
    <Layout className={ styles.shortHeightContent } showIsi={ false }>
      <Title pageName={'Get updates'} center={true}>
        <div className='pt-[2.3rem]'>
          Thank you<br className='block desktop:hidden'/> for signing up
        </div>
      </Title>
      <div className={ styles.content }>
        <GlobalWrapper className={'flex-1 tablet:w-full'} showBorderLeft={ true }>
          <ThankYou buttonLink="/" buttonText="Return Home">
            <p className='mt-[1.5rem] font text-center font-MontserratMedium'>
              You will begin receiving<br/> information about LOQTORZI<sup>®</sup>.
            </p>
          </ThankYou>

          <Footnotes className='mt-[2.6rem] pb-[2rem] px-[1rem]' center={true} items={[
            {
              bullet: '',
              text: <span>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
            }
          ]} />
        </GlobalWrapper>
      </div>
    </Layout>
  );
}